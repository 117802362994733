<template>
  <div class="field">
    <b-field :label="label">
      <b-input
        ref="pass1"
        v-model="pass1"
        :disabled="disabled"
        type="password"
        autocomplete="password"
        placeholder="8+ characters"
        @focus="inFocus = true"
      />
    </b-field>

    <b-field v-show="confirm && isValid" label="Confirm password">
      <b-input
        ref="pass2"
        v-model="pass2"
        :disabled="disabled"
        type="password"
        autocomplete="password"
        placeholder="Confirm password"
        @focus="inFocus = true"
      />
    </b-field>

    <b-field v-if="inFocus || pass1.length || pass2.length">
      <article
        :class="{ 'is-success': isValidConfirmed }"
        class="message is-size-7"
      >
        <div class="message-body">
          <p class="has-margin-bottom-25">
            <b-icon
              :icon="isEightCharacters ? 'check-circle' : 'circle'"
              :pack="isEightCharacters ? 'fas' : 'far'"
              :type="isEightCharacters ? 'is-success' : 'is-dark'"
              size="is-small"
            />
            <template>
              Password is at least 8 characters
            </template>
          </p>

          <!-- <p class="has-margin-bottom-25"><b-icon
            :icon="hasUpperCaseLetters ? 'check-circle' : 'circle'"
            :pack="hasUpperCaseLetters ? 'fas' : 'far'"
            :type="hasUpperCaseLetters ? 'is-success' : 'is-dark'"
            size="is-small"
          /> <template>Password contains uppercase letters</p></template>

          <p class="has-margin-bottom-25"><b-icon
            :icon="hasLowerCaseLetters ? 'check-circle' : 'circle'"
            :pack="hasLowerCaseLetters ? 'fas' : 'far'"
            :type="hasLowerCaseLetters ? 'is-success' : 'is-dark'"
            size="is-small"
          /> <template>Password contains lowercase letters</p></template>-->

          <p class="has-margin-bottom-25">
            <b-icon
              :icon="hasLetters ? 'check-circle' : 'circle'"
              :pack="hasLetters ? 'fas' : 'far'"
              :type="hasLetters ? 'is-success' : 'is-dark'"
              size="is-small"
            />
            <template>
              Password contains at least one letter
            </template>
          </p>

          <p class="has-margin-bottom-25">
            <b-icon
              :icon="hasDigits ? 'check-circle' : 'circle'"
              :pack="hasDigits ? 'fas' : 'far'"
              :type="hasDigits ? 'is-success' : 'is-dark'"
              size="is-small"
            />
            <template>
              Password contains at least one number
            </template>
          </p>

          <!-- <p><b-icon
            :icon="hasSpecialCharacters ? 'check-circle' : 'circle'"
            :pack="hasSpecialCharacters ? 'fas' : 'far'"
            :type="hasSpecialCharacters ? 'is-success' : 'is-dark'"
            size="is-small"
          /> <template>Password contains special characters</p></template>-->

          <p v-if="confirm && isValid" class="has-margin-top-25">
            <b-icon
              :icon="isConfirmed ? 'check-circle' : 'circle'"
              :pack="isConfirmed ? 'fas' : 'far'"
              :type="isConfirmed ? 'is-success' : 'is-dark'"
              size="is-small"
            />
            <template>
              Password is confirmed
            </template>
          </p>
        </div>
      </article>
    </b-field>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    confirm: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    expand: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: "Password"
    }
  },

  data() {
    return {
      pass1: "",
      pass2: "",
      inFocus: this.expand
    };
  },

  computed: {
    password() {
      return this.pass1.length ? this.pass1 : this.pass1 || this.pass2;
    },

    isEightCharacters() {
      return this.$v.isLength(this.password, { min: 8 });
    },

    hasLetters() {
      return /[a-zA-Z]/.test(this.password);
    },

    hasUpperCaseLetters() {
      return /[A-Z]/.test(this.password);
    },

    hasLowerCaseLetters() {
      return /[a-z]/.test(this.password);
    },

    hasDigits() {
      return /[0-9]/.test(this.password);
    },

    hasSpecialCharacters() {
      return this.$v.isLength(this.password.replace(/[A-Za-z0-9]/g, ""), {
        min: 1
      });
    },

    isConfirmed() {
      if (!this.confirm) return true;
      return this.pass1.length && this.pass1 === this.pass2;
    },

    isValid() {
      return this.isEightCharacters && this.hasLetters && this.hasDigits;
    },

    isValidConfirmed() {
      return this.isValid && this.isConfirmed;
    }
  },
  watch: {
    isValidConfirmed() {
      this.$emit("validated", this.isValidConfirmed ? this.password : "");
    }
  },
  created() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.pass1.$el.querySelector("input").focus();
      });
    }
  },
  methods: {
    reset() {
      this.pass1 = "";
      this.pass2 = "";
    }
  }
};
</script>
